import axios, { AxiosResponse } from 'axios';

import { Bot, BotLabel, ConversationHistory, NewBot } from '@/domain/bot';
import {
    BotMappie,
    BotMappieNewVersion,
    BotMappieVersion,
} from '@/domain/bot-mappie';
import { IdsAndPermissions } from '@/domain/call-details';
import { HistoryFilter } from '@/domain/data-grid-filter';

const BOTS_API = '/api/bots';

export const getBots = async (accountId?: string | null) => {
    const response = await axios.get<BotMappie[]>(BOTS_API, {
        params: {
            account_id: accountId,
        },
    });
    return response.data;
};

export const getBot = async (botId: string) => {
    const response = await axios.get<Bot>(`/${botId}`, {
        baseURL: BOTS_API,
    });
    return response.data;
};

export const getBotRaw = async (botId: string, accountId?: string | null) => {
    const response = await axios.get<BotMappie>(`/${botId}/raw`, {
        baseURL: BOTS_API,
        params: {
            account_id: accountId,
        },
    });
    return response.data;
};

export const postBot = async (newBot: NewBot, accountId: string | null) => {
    const response = await axios.post<NewBot, AxiosResponse<BotMappie>>(
        BOTS_API,
        newBot,
        {
            params: {
                account_id: accountId,
            },
        },
    );
    return response.data;
};

export const postBotRaw = async (
    newBot: BotMappie,
    accountId?: string | null,
) => {
    const response = await axios.post<BotMappie, AxiosResponse<BotMappie>>(
        '/raw',
        newBot,
        {
            baseURL: BOTS_API,
            params: {
                account_id: accountId,
            },
        },
    );
    return response.data;
};

export const deleteBot = async (botId: string, accountId?: string | null) => {
    await axios.delete<null>(`/${botId}`, {
        baseURL: BOTS_API,
        params: {
            account_id: accountId,
        },
    });
    return;
};

export const getBotVersions = async (botId: string) => {
    const response = await axios.get<BotMappieVersion[]>(
        `/${botId}/bot_versions`,
        {
            baseURL: BOTS_API,
        },
    );
    return response.data;
};

export const getBotVersion = async (
    botId: string,
    botVersionId: string,
    accountId?: string,
) => {
    const response = await axios.get<Bot>(
        `/${botId}/bot_versions/${botVersionId}`,
        {
            baseURL: BOTS_API,
            params: {
                account_id: accountId,
            },
        },
    );
    return response.data;
};

export const postBotVersion = async (
    botId: string,
    botNewVersion: BotMappieNewVersion,
) => {
    const response = await axios.post<
        BotMappieNewVersion,
        AxiosResponse<{ id: string }>
    >(`/${botId}/bot_versions`, botNewVersion, {
        baseURL: BOTS_API,
    });
    return response.data;
};

export const patchBotVersion = async (
    botId: string,
    botVersionId: string,
    updatedBotVersion: Omit<Bot, 'botId' | 'botVersionId' | 'labels'>,
    accountId?: string,
) => {
    await axios.patch<BotMappieVersion>(
        `/${botId}/bot_versions/${botVersionId}`,
        updatedBotVersion,
        {
            baseURL: BOTS_API,
            params: {
                account_id: accountId,
            },
        },
    );
    return;
};

export const getBotCallHistory = async (
    idsAndPermissions: IdsAndPermissions,
    paginationModel: {
        page: number;
        pageSize: number;
    },
    filters: HistoryFilter,
) => {
    const response = await axios.get<ConversationHistory>(
        `/${idsAndPermissions.botId}/call_history`,
        {
            baseURL: BOTS_API,
            params: {
                account_id: idsAndPermissions.accountId,
                is_chat: idsAndPermissions.isChat,
                is_kpi: idsAndPermissions.isKPICall,
                page: paginationModel.page,
                page_size: paginationModel.pageSize,
                filters: JSON.stringify(filters),
            },
        },
    );
    return response.data;
};

export const getBotChatHistory = async (botId: string, accountId?: string) => {
    const response = await axios.get<ConversationHistory>(
        `/${botId}/chat_history`,
        {
            baseURL: BOTS_API,
            params: {
                account_id: accountId,
            },
        },
    );
    return response.data;
};

export const getAvailableBotLabels = async (accountId?: string | null) => {
    const response = await axios.get<BotLabel[]>(`/labels`, {
        baseURL: BOTS_API,
        params: {
            account_id: accountId,
        },
    });
    return response.data;
};

export const patchBotLabels = async (
    botId: string,
    labels: string[],
    accountId?: string | null,
) => {
    await axios.patch(`/${botId}/labels`, labels, {
        baseURL: BOTS_API,
        params: {
            account_id: accountId,
        },
    });
    return;
};

export const publishBot = async (
    machrBotId: string,
    botId: string,
    botVersionId: string,
    accountId?: string | null,
) => {
    await axios.post(
        `/${botId}/bot_versions/${botVersionId}/publish?machr_bot_id=${machrBotId}`,
        {},
        {
            baseURL: BOTS_API,
            params: {
                account_id: accountId,
            },
        },
    );
};
